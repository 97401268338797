var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"id":"main-nav"}},[_c('ul',{staticClass:"second-nav"},[(_vm.user_id != 0)?_c('li',[_c('a',{staticClass:"widget-header mr-3",attrs:{"href":"/my_order"}},[_vm._v("My Orders")])]):_vm._e(),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),(_vm.user_id != 0)?_c('li',[_c('a',{attrs:{"href":"contact-us"}},[_vm._v("Contact Us")])]):_vm._e(),(_vm.user_id != 0)?_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("User")]),_vm._m(4)]):_vm._e()]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"widget-header mr-3",attrs:{"href":"/about_us"}},[_vm._v("About Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"widget-header mr-3",attrs:{"href":"/our_chefs"}},[_vm._v("Our Chefs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"widget-header mr-3",attrs:{"href":"/kitchen_tour"}},[_vm._v("Kitchen Tour")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"widget-header mr-3",attrs:{"href":"/pasta_history"}},[_vm._v("Pasta History")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/profile"}},[_vm._v("Account Settings")])]),_c('li',[_c('a',{attrs:{"href":"/change_password"}},[_vm._v("Change Password")])]),_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"href":"/login"}},[_vm._v("Logout")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"bottom-nav"},[_c('li',{staticClass:"email"},[_c('a',{staticClass:"text-danger",attrs:{"href":"/home"}},[_c('p',{staticClass:"h5 m-0"},[_c('i',{staticClass:"feather-home text-danger"})]),_vm._v(" Home ")])]),_c('li',{staticClass:"ko-fi"},[_c('a',{attrs:{"href":"/contact-us"}},[_c('p',{staticClass:"h5 m-0"},[_c('i',{staticClass:"feather-phone"})]),_vm._v(" Help ")])])])
}]

export { render, staticRenderFns }