<template>
  <div v-if="order" class="col-12 px-0 text-left">
    <div style="display: none;" hidden >{{ DFees }}</div>
    <div class="osahan-cart-item rounded shadow-sm
                border overflow-hidden bg-white sticky_sidebar">
      <div v-if="$store.state.restaurant" class="d-flex border-bottom  shadow-sm osahan-cart-item-profile bg-white p-3">
        <img src="/img/logo_web.png" class="mr-3 rounded-circle img-fluid"
          style="width: 60px;max-height: 60px;background: #663435;padding: 4px;" />
        <!-- <img
          :src="$store.state.baseURL + '/' + $store.state.restaurant.image"
          class="mr-3 rounded-circle img-fluid"
          style="width:50px;max-height:50px;"
          
        /> -->
        <div v-if="$store.state.restaurant" class="d-flex flex-column">
          <h6 class="mb-1 font-weight-bold">
            {{ $store.state.restaurant.res_name_en }}
          </h6>
          <p class="mb-0 small text-muted">
            <i class="feather-map-pin"></i>
            {{ $store.state.restaurant.branch_status }}
          </p>
        </div>
      </div>
      <div v-if="order" class="bg-white pb-2 pt-0">
        <div v-for="(item, index) in order.items" v-bind:key="index">
          <a v-on:click="delOrderItem(index)" class="btn text-danger mt-1 mr-2 btn-sm float-right p-1"
            style="height: 14px;line-height: 2px;position: absolute;right: 0px;">x</a>
          <div class="font-weight-bold p-1 pl-2 gold-members col-11">
            <!-- <div style="margin-top: 2px" class="float-left mr-2 text-success">
              &middot;
            </div> -->
            <div>{{ item.name_en }}</div>
          </div>
          <div class="
              gold-members
              d-flex
              align-items-top
              justify-content-between
              p-1
            ">
            <div class="media align-items-top col-8">
              <div class="media-body" style="font-size: 0.8em">
                <span style="display: block" class="text-info pr-2">
                  <p class="float-left mx-0 px-0 col-9 mb-1 text-info">
                    {{ item.size_en }}
                  </p>
                  <span class="float-md-right">{{
                    Math.round(item.price * 100) / 100 + " " + currency
                  }}</span>
                </span>

                <span style="display: block" class="text-muted pr-2" v-for="option in item.options"
                  v-bind:key="option.id">
                  <p class="float-left col-9 mx-1 mb-1">
                    <i class="feather-arrow-right text-success"></i> {{ option.name }}
                  </p>
                  <span class="float-md-right">
                    {{
                      parseFloat(option.price) == 0
                        ? ""
                        : "(+" + Math.round(parseFloat(option.price) * 100) / 100 + " " + currency + ")"
                    }}</span>
                </span>
                <span style="display: block" class="text-muted pr-2" v-for="extra in item.extras" v-bind:key="extra.id">
                  <p class="float-left col-9 mb-1">
                    <i class="feather-arrow-right"></i> {{ extra.name }}
                  </p>
                  <span class="float-md-right">
                    {{
                      parseFloat(extra.price) == 0
                        ? ""
                        : "(+" + extra.price + " " + currency + ")"
                    }}</span>
                </span>
              </div>
            </div>
            <div class="align-items-top col-4 text-right" style="min-width:  100px">
              <div class="count-number" style="min-width: 65px;line-height: normal;">
                <button v-on:click="item.count > 1 ? item.count-- : (item.count = 1)" type="button"
                  class="btn-sm left inc btn text-white"
                  style="background-color: #663435;border-color: #663435;border-radius: 50% 0 0 50%;border-right: 0px;">
                  <i class="feather-minus"></i></button>
                <input class="count-number-input text-white" type="number" readonly style="-webkit-appearance: none"
                  :value="item.count" />
                <button v-on:click="item.count++" type="button" class="btn-sm right inc btn text-white"
                  style="background-color: #663435;border-color: #663435;border-radius: 0 50% 50% 0;border-left: 0px;">
                  <i class="feather-plus"></i>
                </button>
              </div>
              <div style="line-height: 24px" class="mb-0 text-right text-muted">
                <div class="pt-1">
                  {{ currency + " " + Math.round(item.total * item.count * 100) / 100 }}
                </div>
              </div>
            </div>
          </div>
          <div class="border-bottom">
            <input placeholder="Enter any special request note"
              class="form-control col-11 small form-control-sm m-1 ml-2 mb-2" type="text" v-model="item.special" />
          </div>
        </div>

      </div>
      <div class="bg-white p-3 py-3 border-bottom clearfix">
        <div class="input-group-sm mb-2 input-group">
          <input v-model="couponCode" placeholder="Enter Coupon Code" type="text" :disabled="appliedCoupon"
            class="form-control" />
          <div class="input-group-append">
            <a v-show="!appliedCoupon" v-on:click="applyCoupon" class="btn btn-primary"
              style="border-radius:0px !important;">
              <i class=""></i> APPLY
            </a>
            <a v-show="appliedCoupon" v-on:click="
              couponCode = null;
            appliedCoupon = null;
            " class="btn btn-primary">
              <i class=""></i> REMOVE
            </a>
          </div>
        </div>
        <div class="mb-0 input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather-message-square"></i></span>
          </div>
          <textarea v-model="order.notes" placeholder="Any notes? please enter it here." aria-label="With textarea"
            class="form-control"></textarea>
        </div>
      </div>
      <div v-if="branch && order" class="bg-white p-3 clearfix border-bottom">
        <p class="mb-1">
          Subtotal
          <span class="float-right text-dark">{{
            currency + " " + (Math.round(subTotal * 100) / 100)
          }}</span>
        </p>
        <p style="text-transform: capitalize" v-if="appliedCoupon" class="mb-1 row">
          <span class="col-9">{{ appliedCoupon.description_en }}</span>
          <span v-show="discount > 0" class="col-3 text-right float-right text-dark">-{{ discount + " " +
            currency }}</span>
        </p>
        <p class="mb-1">
          Delivery Fees
          <span class="float-right text-dark">{{ delivery_fees }}</span>
        </p>
        <p class="mb-1" v-if="VAT > 0">
          VAT<span class="float-right text-dark">{{ VAT }}</span>
        </p>

        <hr />
        <h6 class="font-weight-bold mb-0">
          TO PAY <span class="float-right">{{ currency + " " + grandTotal }} </span>
        </h6>
      </div>

      <div class="p-3">
        <a v-if="order" v-show="order.items.length > 0" v-on:click="orderNow"
          class="btn btn-primary btn-block btn-lg text-white">
          {{ actionBtnMethod == "checkout" ? "Proceed to Checkout" : "PLACE ORDER" }}
          <!-- <i class="feather-arrow-right"></i> -->
        </a>
        <a v-if="order" v-show="order.items.length == 0"
          class="btn btn-outline-primary text-muted disabled btn-block btn-lg" href="#">{{ actionBtnMethod == "checkout"
            ?
            "Proceed to Checkout" : "PLACE ORDER"
          }}
          <!-- <i class="feather-arrow-right"></i> -->
        </a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      notes: "",
      couponCode: null,
      appliedCoupon: null,
      currency: ""
    };
  },
  props: [
    "actionBtnMethod",
    "order",
    "branch",
    "DFees",
    "TableNumber",
    "RoomNumber",
    "RestaurantId",
  ],
  methods: {
    orderNow() {
      let BreakException = {};
      const tableParam = this.TableNumber != undefined ? this.TableNumber : "";
      const roomParam = this.RoomNumber != undefined ? this.RoomNumber : "";
      const CustomerType =
        tableParam != "" ? "table" : roomParam != "" ? "room" : "";
      const filledParam =
        CustomerType == "room"
          ? roomParam
          : CustomerType == "table"
            ? tableParam
            : "";

      try {
        if (this.actionBtnMethod == "orderNow") {
          let ordr = JSON.parse(window.localStorage.getItem("curOrder"))
          if ((!ordr.address || ordr.address == 0) && CustomerType == "") {
            alert("Select Address First, Before Ordering");

            throw BreakException;
          }
          let payment = 1;
          let address = ordr.address;
          let area = window.localStorage.getItem("selArea");
          let branch = this.branch.id;
          let delivery_type = 1;
          let coupon = "";
          if (this.appliedCoupon) coupon = this.appliedCoupon.code;

          let device_id = "";
          let notes = ordr.notes;

          let itemsStr = "";
          for (let i = 0; i < ordr.items.length; i++) {
            const e = ordr.items[i];
            let extStr = "";
            if (e.extras) {
              for (let ex = 0; ex < e.extras.length; ex++) {
                const exEle = e.extras[ex];
                extStr += `${exEle.id},`;
              }
              extStr = extStr.substring(0, extStr.length - 1);
            }
            let optStr = "";
            if (e.options) {
              for (let op = 0; op < e.options.length; op++) {
                const opEle = e.options[op];
                optStr += `${opEle.id},`;
              }
              optStr = optStr.substring(0, optStr.length - 1);
            }

            itemsStr += `{"id":${e.menu_item_id},"choices":[],"extras":[${extStr}],"options":[${optStr}],"count":${e.count},"special":"${e.special}"},`;
          }
          itemsStr = itemsStr.substring(0, itemsStr.length - 1);

          const items = `{"items":[${itemsStr}]}`;
          let apiURL =
            `${this.$store.state.baseURL}/api/` +
            `orders/create?restaurant_id=${this.RestaurantId
            }&delivery_type=${delivery_type}&type=${CustomerType}&table_num=${tableParam}&room=${roomParam}&payment=${payment}&lat=0&lng=0&address=${CustomerType == ""
              ? address
              : `${CustomerType}-${roomParam}${tableParam}`
            }&area=${area}&branch=${branch}&items=${items}&device_id=${device_id}&notes=${notes}&time=&car_model=&car_color=&gift_cards=&coins=00.00&coupon=${coupon}`;

          apiURL += `&api_token=${window.localStorage.getItem("jwt")}`
          // == call create Order Api ==

          fetch(apiURL, {
            method: "POST",
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                alert(
                  "Server returned " +
                  response.status +
                  " : " +
                  response.statusText
                );
              }
            })
            .then((response) => {
              if (response.response && response.data.order_code != "") {
                this.result = response.data;
                // clear curOrder in local storage and order Object
                window.localStorage.setItem(
                  "lastOrder",
                  JSON.stringify(this.order)
                );
                window.localStorage.setItem(
                  "lastOrderCode",
                  JSON.stringify(this.result.order_code)
                );
                window.localStorage.removeItem("curOrder")
                this.order.items = []
                this.order.notes = ""
                this.order.address = 0
                window.location = "/successful"
              } else {
                if (response.message) console.log(response.message)
                else if (response.messages) alert(response.messages)
              }
            })
            .catch((err) => {
              console.log(err)
            });
        } else {
          window.location = `/checkout${CustomerType != "" ? `/${CustomerType}` : ""
            }${filledParam != "" ? `/${filledParam}` : ""}`;
        }
      } catch (error) {
        if (error == BreakException) console.log("break");
      }
    },
    applyCoupon() {
      if (this.couponCode) {
        let url =
          this.$store.state.baseURL +
          "/api/coupon/validation?api_token=" +
          window.localStorage.getItem("jwt") +
          "&coupon=" +
          this.couponCode;
        let request = new Request(url, { method: "GET" });
        fetch(request)
          .then((r) => {
            if (r.ok) {
              return r.json();
            }
          })
          .then((r) => {
            if (r.response) {
              this.appliedCoupon = r.coupon;
              window.localStorage.setItem(
                "appliedCoupon",
                JSON.stringify(this.appliedCoupon)
              );
            } else {
              if (r.message) console.log(r.message);
              if (r.messages) console.log(r.messages);
              if (r.message == "Invalid Token") {
                window.location = "/login";
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    delOrderItem(index) {
      let res = confirm("are you sure");
      if (res) {
        this.order.items.splice(index, 1);
        this.$store.commit("setCurOrder", this.order);
      }
    },
  },
  watch: {
    '$store.state.curOrder.': {
      deep: true,
      handler(val) {
        let curOrder = JSON.parse(window.localStorage.getItem('curOrder'))
        if (curOrder) {
          if (curOrder.address == null) {
            this.order.address = null
          }
        }
      }
    },
    order: {
      handler(val) {
        this.$store.commit('setCurOrder', val)
      },
      deep: true,
    },
    appliedCoupon(val) {
      if (val) {
        this.couponCode = val.code;
      }
    },
  },
  computed: {

    delivery_fees() {
      //let dfees = this.branch.area.delivery_fees;
      
      let delfees = this.DFees
      if (this.appliedCoupon) {
        if (this.appliedCoupon.discount_for_amount_more_than <= this.subTotal) {
          if (this.appliedCoupon.free_delivery == "1") {
            delfees = 0;
          }
        }
      }
      return delfees;
    },
    discount() {
      let disc = 0;
      let coupon = this.appliedCoupon;

      if (coupon) {
        if (coupon.discount_for_amount_more_than <= this.subTotal) {
          if (coupon.include_items != null) {
            this.$store.dispatch("UpdateCurrentOrder");
            let curOrder = JSON.parse(this.$store.state.curOrder)
            let applyToItems = coupon.include_items;
            if (applyToItems.length > 0)
              curOrder.items.forEach((item) => {
                let foundItem = applyToItems.indexOf(item.menu_item_id);
                console.log(foundItem);
              });
          } else {
            if (coupon.fixed != null) {
              if (coupon.fixed > 0) {
                disc = coupon.fixed;
                console.log(disc);
              }
            } else if (coupon.percentage != null) {
              if (coupon.percentage > 0) {
                disc = (coupon.percentage / 100) * this.subTotal;
              }
            }
          }
        }
        if (coupon.max_discount_amount > 0) {
          if (disc > coupon.max_discount_amount) {
            disc = coupon.max_discount_amount;
          }
        }
      }

      return Math.round(disc * 100) / 100;
    },
    subTotal() {
      var total = 0;
      if (this.order != null) {
        var items = this.order.items;
        for (let index = 0; index < items.length; index++) {
          const element = this.order.items[index];
          total = total + element.total * element.count;
        }
      }
      return total;
    },
    VAT() {
      var vat = 0;
      let vr = this.$store.state.vatRate / 100;
      let gt = this.subTotal + parseFloat(this.delivery_fees);
      vat = gt * vr;
      return Math.round(vat * 100) / 100;
    },
    grandTotal() {
      let duetotal = 0;
      duetotal =
        this.subTotal -
        this.discount +
        this.VAT +
        parseFloat(this.delivery_fees);
      return Math.round(duetotal * 100) / 100;
    },
  },
  mounted() {
    this.currency = window.currency;
    if (window.localStorage.getItem("appliedCoupon")) {
      this.appliedCoupon = JSON.parse(
        window.localStorage.getItem("appliedCoupon")
      );
    }
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
  /* Firefox */
}
</style>